body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-1 {
  background: #08101d url('assets/bg-1.png');
  background-size: cover;
}

.invalid {
  border: 1px solid red;
}

h3,
h3.MuiTypography-root {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 30px;
  /* text-transform: uppercase; */
  color: #0d1628;
}

h4 {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: #0d1628;
}

h5 {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 18px;
  /* text-transform/: uppercase; */
}